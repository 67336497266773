// simport lightGallery from 'lightgallery.js'
require('lightgallery.js/dist/css/lightgallery.min.css')
// require('lightgallery.js/src/sass/lightgallery.scss')



require(['lightgallery.js'], function() {
    // require(["lg-zoom.js", "lg-thumbnail.js"], function(){

        galleries = document.getElementsByClassName('lightgallery')
        console.log(galleries)

        for (let gallery of galleries) {
            lightGallery(gallery,{
                thumbnail:true,
            }); 
        }
    // });
});

// lightGallery(document.getElementById('lightgallery'));